@import '../../../../scss/theme-bootstrap';

$disable-bbv2-in-checkout: false !default;
// Set only "body" as default, a selector will be disabled when it has the pseudo-class ":not".
$selector-to-disable: 'body' !default;

@if $disable-bbv2-in-checkout == true {
  $selector-to-disable: 'body:not(.device-mobile):not(.pg-checkout), body.device-mobile > :not(.pg-checkout)';
}

.site-footer {
  &-contact {
    display: none;
  }
  &__wrap {
    max-width: none;
    margin: 0;
    background: $color-black;
    color: $color-white;
    padding: 30px 0 0 0;
  }
  a.sticky-back-to-top {
    $ldirection: 16px;
    margin-top: 2px;
    top: 77%;
    @include breakpoint($medium-up) {
      margin-top: 0;
      top: 88%;
    }
  }
  div.sticky-offer {
    visibility: hidden;
  }
}

@if $disable-bbv2-in-checkout == true {
  #{$selector-to-disable} {
    &.site-footer {
      margin-top: 15px;
    }
  }
  .device-mobile .pg-checkout .site-footer__wrap {
    background: none;
    color: $color-black;
  }
}
#{$selector-to-disable} {
  .site-footer {
    float: none;
    .site-footer__main {
      max-width: $max-width-large;
      margin: 0 auto;
      border: none;
      padding: 20px 0;
    }
    &__column,
    &__column:nth-child(2) {
      border: none;
      padding-#{$ldirection}: 25px;
      @include breakpoint($medium-up) {
        width: 30%;
      }
      .menu {
        padding: 0;
        border: none;
        font-size: 15px;
      }
      .menu__item {
        font-family: $body-font;
        float: none;
        line-height: 15px;
        font-size: 15px;
        color: $color-white;
        a,
        a:hover {
          color: $color-white;
          margin-bottom: 13px;
        }
      }
    }
    &__column:last-child {
      @include breakpoint($medium-up) {
        width: 40%;
      }
    }
    &__column-title {
      font-family: $body-font-bold;
      font-size: 16px;
      letter-spacing: 2px;
      color: $color-white;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
    &__trustmark-image {
      text-align: center;
      padding-bottom: 20px;
      @include breakpoint($medium-up) {
        text-align: #{$ldirection};
        padding: 0;
      }
    }
    &-social-links,
    .site-email-signup {
      padding: 0;
      border: 0;
    }
    .site-email-signup {
      margin: 31px 0;
      @include breakpoint($medium-up) {
        margin: 0 0 100px 0;
      }
      &__field {
        width: 100%;
        max-width: 275px;
        border: 1px solid $color-white;
        border-#{$rdirection}: none;
        color: $color-white;
        background: $color-black;
        height: 45px;
        padding: 0 18px;
        margin: 0;
        &::placeholder {
          color: $color-white;
        }
        &:focus {
          border-color: $color-white;
        }
      }
      &__submit {
        height: 45px;
        background-color: $color-black;
        border: solid 1px $color-white;
        &:hover {
          background-color: $color-cta-hover;
        }
      }
    }
    &-social-links {
      &__header {
        font-size: 16px;
        letter-spacing: 2px;
        padding: 10px 0 26px;
        @include breakpoint($medium-up) {
          margin: 0;
        }
      }
      .icon {
        margin-#{$rdirection}: 15px;
        float: none;
        &:before {
          font-size: 32px;
        }
      }
      a,
      a:hover {
        color: $color-white;
        margin-bottom: 13px;
      }
      &__menu {
        display: flex;
        flex-wrap: wrap;
      }
      &__link {
        margin-#{$rdirection}: 15px;
        float: none;
      }
      &__item {
        margin-bottom: 15px;
      }
      &__blog-naver-link {
        background-image: url('../../bb_base/img/icons/svg/naver--white.svg');
        background-repeat: no-repeat;
        display: block;
        width: 28px;
        height: 100%;
        &:hover {
          background-image: url("../../bb_base/img/icons/svg/naver--pink.svg");
        }
        &:before {
          width: auto;
          content: '';
          display: inline-block;
          height: 100%;
        }
      }
      &__icon-line {
        background: url('#{$base-theme-path}img/icons/src/line.svg') 0 0 no-repeat;
        width: 30px;
        height: 30px;
        bottom: 4px;
        position: relative;
      }
      &__link--line {
        @include breakpoint($medium-up) {
          line-height: 48px;
        }
      }
    }
    &__country-chooser {
      position: relative;
    }
    .language-select {
      margin: 0 0 15px 0;
      top: 16px;
      &__item {
        margin-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
        &:after {
          position: relative;
          content: '|';
          padding-#{$ldirection}: 5px;
          top: -1px;
        }
        &:last-child:after {
          content: none;
        }
        a {
          color: $color-white;
        }
      }
      &__link {
        display: inline-block;
      }
    }
    .country-select__selectbox {
      color: $color-white;
      border: 1px solid $color-white;
      width: 93%;
      height: 45px;
      margin: 0 0 25px 0;
      background-repeat: no-repeat;
      padding-#{$ldirection}: 40px;
      &.selectboxflag {
        background-position: 16px 16px;
      }
      @include breakpoint($medium-up) {
        min-width: 200px;
        width: 70%;
        background-color: $color-black;
        padding-top: 5px;
      }
      &--language-on {
        width: 300px;
        @include breakpoint($medium-up) {
          width: 70%;
        }
      }
      &:hover {
        margin-bottom: 25px;
      }
      .selectBox-label {
        position: relative;
      }
      .selectBox-arrow {
        background: none;
        padding-top: 5px;
        #{$rdirection}: 5px;
      }
    }
    select.country-select__selectbox {
      background-position: 97% 50%;
      background-image: url('/media/images/global/icon-dropdown-white.png');
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0 0 0 40px);
      }
    }
    &-toggle-device {
      margin: 0;
      @include breakpoint($medium-up) {
        display: flex;
        width: 100%;
        .copyright {
          @include swap_direction('margin', 0 20px 0 0);
          width: auto;
          flex: 1 0 50%;
          bottom: 0;
          position: static;
          display: block;
          padding: 13px 0;
        }
      }
      a.toggle-mobile {
        color: $color-white;
        border-bottom-color: $color-white;
        padding-bottom: 4px;
        display: inline-block;
      }
    }
    .basic-responsive {
      @include breakpoint($medium-up) {
        padding-top: 15px;
      }
      .copyright {
        @include breakpoint($medium-up) {
          display: inline-block;
          width: 56%;
          color: $color-white;
          font-size: 15px;
          position: initial;
          text-align: #{$ldirection};
          vertical-align: top;
          margin: 0;
          padding: 0;
        }
      }
      .footer-column {
        @include breakpoint($medium-up) {
          display: inline-block;
          width: 20%;
          font-size: 15px;
          padding: 0;
          line-height: 18px;
          position: relative;
          #{$rdirection}: 15px;
          a {
            color: $color-white;
            font-family: $bbtext-regular;
            margin-bottom: 13px;
            text-align: #{$ldirection};
          }
        }
      }
    }
    &__wrap {
      .block-template-basic-responsive-v1,
      .block-template-site-footer-v1 {
        margin: 0;
        background: $color-black;
        color: $color-white;
        padding: 0;
        .site-footer-toggle-device,
        .bb_copyright {
          padding: 0 20px 10px;
          background: $color-black;
          color: $color-white;
        }
      }
    }
  }
}
//hide main footer for checkout
.pg-checkout {
  .site-footer__main {
    display: none;
  }
}
